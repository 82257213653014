$(document).ready(function () {
  //set downloader alerts
  $(".btn-download").click(function(){
    $("#download-alert").show();
       setTimeout(function() { 
       $('#download-alert').fadeOut(); 
   }, 5000);
  });
  
  //Video
  $("#video-wrapper").click(function(){
    $(this).find('iframe').show();
    $(this).find('iframe')[0].src += "?autoplay=1";
  });
//filter
$("#filter .btn").each(function() {
  $(this).on("click", function(){
    var filtertag = 'indicator_card';
    if($(this).hasClass("indicator_card_infrastructure")){
      filtertag = 'indicator_card_infrastructure';
    }
    else if($(this).hasClass("indicator_card_investment")){
      filtertag = 'indicator_card_investment';
    }
    else if($(this).hasClass("indicator_card_people")){
      filtertag = 'indicator_card_people';
    }
    else if($(this).hasClass("indicator_card_impact")){
      filtertag = 'indicator_card_impact';
    }
    else if($(this).hasClass("indicator_card_ecosystem")){
      filtertag = 'indicator_card_ecosystem';
    }
    
      // $('.indicator_card').show();
      // $('.indicator_card.' + filtertag).toggle();
      // $('.indicator_card:not(.' + filtertag + ')').hide();

      if($('.indicator_card.' + filtertag).is(":visible") ){
        $('.indicator_card.' + filtertag).hide();
      }
      else{
        $('.indicator_card.' + filtertag).show();
      }

      if($(this).hasClass('selected')){
        $(this).removeClass('selected');
      }
      else{
        $(this).addClass('selected');
      }
  });
});
      //Mobile Navigation dropdown
      $('.dropdown-submenu a.test').on("click", function(e){
        $(this).next('ul').toggle();
        e.stopPropagation();
        e.preventDefault();
      });

// End of document ready function
});
